var site = site || {};
/**
 * Display the Loyalty Prices available
 */
Drupal.behaviors.loyaltyPrices = {
  /**
   * Flag to ensure this JS is only attached once.
   * @type {boolean}
   */
  attached: false,
  attach: function(context) {
    var self = this;
    var isSPP = $(context.body).hasClass('page-product');
    var isMPP = $(context.body).hasClass('not-front') && ($(context.body).hasClass('page-node') || $(context.body).hasClass('page-products'));
    var isHP = $(context.body).hasClass('front');
    var isViewcart = $(context.body).is('#viewcart');
    var isAccountLandingBestSellers = $(context.body).is('#landing') && $('.best-sellers', context).length;

    if (this.attached || ((isSPP || isMPP) && $('.js-price-loyalty', context).length < 1)) {
      return null;
    } else if (this.attached || isHP && $('.pcg-product-image-title .product-price', context).length < 1) {
      return null;
    } else if (this.attached || isViewcart && $('.recommended-item .formatted_price', context).length < 1) {
      return null;
    }

    site.onLoadRpc.requests = site.onLoadRpc.requests || [];
    site.onLoadRpc.requests.push({
      'method': 'offers.query',
      'params': [{
        'view': 'russia_loyalty'
      }],
      'isSPP': isSPP,
      'isMPP': isMPP,
      'isHP': isHP,
      'isAccountLandingBestSellers': isAccountLandingBestSellers,
      'onSuccess': function(response) {
        if (!response || !response.result || !response.result.value || !response.result.value.offers) {
          return;
        }
        var v = response.result.value;

        // Extract the offer status into a loyalty level array
        page_data.offers_data = page_data.offers_data || {};
        page_data.offers_data.loyalty_Offers = page_data.offers_data.loyalty_Offers || [];
        var highest_disc_met = 0;
        var highest_discount = 0;
        $.each(v.offers, function(ocode, offer) {
          var loyalty_offer = {};
          loyalty_offer.offer_code = ocode;
          if (offer.hasOwnProperty('benefit_fields')) {
            loyalty_offer.discount = parseInt(offer.benefit_fields.PercentDiscount.value);

            // If it's the highest so far, update the flag
            if (highest_disc_met < loyalty_offer.discount && parseInt(offer.fields.meets_criteria)) {
              highest_disc_met = loyalty_offer.discount;
            }
            if (highest_discount < loyalty_offer.discount) {
              highest_discount = loyalty_offer.discount;
            }

            page_data.offers_data.loyalty_Offers.push(loyalty_offer);
          }
        });

        // Sort lowest to highest
        page_data.offers_data.loyalty_Offers.sort(function(a, b) {
          return a.discount === b.discount ? 0 : a.discount > b.discount ? 1 : -1;
        });

        // Recognized, but not logged in customer will show their last known status
        // Set the "met" and "too_low" state of their level and clear the anonymous flag
        // (Checks first_name as the state flags default to level 1)
        page_data.is_anonymous_user = site.userInfoCookie.getValue('signed_in') !== '1';
        if (page_data.is_anonymous_user) {
          var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
          if (
            persistentCookie.first_name &&
            persistentCookie.is_loyalty_member === '1' &&
            persistentCookie.loyalty_level
          ) {
            highest_disc_met = page_data.offers_data.loyalty_Offers[persistentCookie.loyalty_level - 1].discount;
            page_data.is_anonymous_user = false;
          }
        }

        // If they don't meet any criteria and *are* signed in, then they're eligible for the lowest level
        if (highest_disc_met === 0 && !page_data.is_anonymous_user) {
          highest_disc_met = page_data.offers_data.loyalty_Offers[0].discount;
        }

        // Filter the offers and set as met only the highest offer that meets_criteria
        $.each(page_data.offers_data.loyalty_Offers, function(i, el) {
          el.met = el.discount === highest_disc_met;
          el.too_low = el.discount < highest_disc_met;
          el.too_high = el.discount > highest_disc_met && el.discount !== highest_discount;
        });

        $(document).on('prodcat.status', function(e, data) {
          var prodcatData;
          if (data.result && data.result.value && data.result.value.products) {
            prodcatData = data.result.value.products;
          }

          if (isSPP) {
            var sppSkuDiscountable = 0;
            if (prodcatData) {
              var sppProduct = page_data['catalog-spp'].products[0];
              var sppProductSkus = $(sppProduct.skus).filter(function() {
                return this.isOrderable === 1;
              });
              if (sppProductSkus.length > 0) {
                loopProducts:
                for (var i = 0; i < prodcatData.length; i++) {
                  if (prodcatData[i] && prodcatData[i].PRODUCT_ID === sppProduct.PRODUCT_ID) {
                    for (var j = 0; j < prodcatData[i].skus.length; j++) {
                      if (prodcatData[i].skus[j].SKU_ID === sppProductSkus[0].SKU_ID) {
                        sppSkuDiscountable = prodcatData[i].skus[j].isDiscountable;
                        break loopProducts;
                      }
                    }
                  }
                }
              }
            }
            if (sppSkuDiscountable) {
              self.setEvents(context);
              $(document).on('productQV:rendered:LoyaltyPrices', function(e, container) {
                if ($(container).hasClass('cart-confirm-wrapper')) {
                  self.setEventsMPP($(container));
                } else {
                  self.setEvents(context);
                }
              });
            } else {
              $('.js-full-price-loyalty--text .js-price-loyalty--header', context).hide();
              $('.js-full-price-loyalty--text .js-price-loyalty--link', context).hide();
            }
          } else if (isMPP || isAccountLandingBestSellers) {
            if (isAccountLandingBestSellers) {
              self.setEventsMPP(context, prodcatData, 'account-landing-bestsellers');
            } else {
              self.setEventsMPP(context, prodcatData);
            }
            $(document).on('productQV:rendered:LoyaltyPrices', function(e, container) {
              if ($(container).hasClass('cart-confirm-wrapper')) {
                self.setEventsMPP($(container));
              } else {
                self.setEventsMPP(container, prodcatData);
              }
            });
          } else {
            if (isHP && $('.product-price.min-loyalty-price, .product-price.loyalty-price-met', context).length === 0) {
              self.setEventsOther(context, '.pcg-product-image-title .product-price', highest_discount, highest_disc_met);
            } else if (isViewcart && $('.formatted_price.min-loyalty-price, .formatted_price.loyalty-price-met, .formatted_price.loyalty-full-price', context).length === 0) {
              self.setEventsOther(context, '.recommended-item .formatted_price', highest_discount, highest_disc_met);
            }
            $(document).on('productQV:rendered:LoyaltyPrices', function(e, container) {
              if ($(container).find('.js-price-loyalty').length > 0) {
                self.setEventsMPP($(container));
              }
              if (isViewcart && $('.formatted_price.min-loyalty-price, .formatted_price.loyalty-price-met, .formatted_price.loyalty-full-price', container).length === 0) {
                self.setEventsOther(container, '.recommended-item .formatted_price', highest_discount, highest_disc_met);
              }
            });
          }
        });
      },
      'onFailure': function() {
        var $productLoyalty;
        if (isSPP && $('.product-full__price-loyalty--text', context).length >= 1) {
          $productLoyalty = $('.product-full__price-loyalty--text');
        } else if (isMPP && $('.js-price-loyalty', context).length >= 1) {
          $productLoyalty = $('.js-price-loyalty');
        }
        if ($productLoyalty) {
          $productLoyalty.hide();
        }
      }
    });
  },
  setEvents: function(context) {
    this.attached = true;
    var $productLoyalty = $('.product-full__price-loyalty--text', context);
    var $miniBagProductLoyalty = $('.spp-product__mini-bag', context);
    var isCartConfirm = $(context).hasClass('cart-confirm-wrapper');
    var tierDescriptionList = $productLoyalty.length && $productLoyalty.attr('data-tier-desc-list') ? $productLoyalty.attr('data-tier-desc-list').split(',') : [];
    var $tmp;
    var $element;
    var $discoverMore = $('.module-may-we-suggest', context);
    tierDescriptionList.map(function(str) {
      return str.trim();
    });

    var currency = $productLoyalty.attr('data-currency-symbol') || '';

    var $productPriceContainer = $productLoyalty.find('.product-full__price-loyalty-container');
    var $container = $productPriceContainer.find('.product-full__price-loyalty');
    var productPrice = $container.find('.product-full__price');

    // Add active class for full price
    if (page_data.is_anonymous_user) {
      $container.addClass('active');
    }
    // Update the mini bag prices
    if ($miniBagProductLoyalty.find('.js-price-loyalty').length || (isCartConfirm && $(context).find('.js-price-loyalty').length)) {
      $element = isCartConfirm ? $(context) : $miniBagProductLoyalty;

      this.setEventsMPP($element);
      // Update the prices for multi-size products
      if ($element.find('select.js-size-price-loyalty').length) {
        this.setEventsMPP($($element.find('select.js-size-price-loyalty')));
      }
    }
    // Update the prices for View More products
    if ($discoverMore.find('.js-price-loyalty').length) {
      $element = $discoverMore;
      this.setEventsMPP($element);
    }
    if (productPrice.length > 0) {
      $productLoyalty.show();
      var loyalty_Offers = page_data.offers_data.loyalty_Offers;
      $.each(loyalty_Offers, function(i, el) {
        // Ignore/hide levels that are below the current user when signed in
        if ((el.too_low || el.too_high) && !page_data.is_anonymous_user) {
          return;
        }

        $tmp = $container.clone();
        $tmp.find('.product-full__price-psize').remove();
        $tmp.removeClass('active');
        $tmp.removeClass('original-price');
        $productPriceContainer.prepend($tmp);

        var priceText = $tmp.find('.product-full__price-text');
        var $descriptionText = $tmp.find('.product-full__price-description--text');

        $tmp.addClass('tier' + (i + 1));

        // Highlight the "met" levels when signed in only
        if (el.met && !page_data.is_anonymous_user) {
          $tmp.addClass('active');
        }

        for (var j = 0, len = productPrice.length; j < len; j++) {
          var textPrice = $(productPrice[j]).text();
          var priceBase, discountedPrice, splitFloatPrice;

          textPrice = textPrice.substring(0, textPrice.lastIndexOf(' ')).replace(/ /g, '');
          priceBase = parseFloat(textPrice);
          discountedPrice = priceBase - priceBase * el.discount / 100;
          if (Drupal.settings.globals_variables && !Drupal.settings.globals_variables.disable_float_price && textPrice.includes('.')) {
            discountedPrice = Number.parseFloat(discountedPrice).toFixed(2);
            if (site.translations.product.loyalty_three_digit_separator) {
              splitFloatPrice = discountedPrice.toString().split('.');
              splitFloatPrice[0] = splitFloatPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, site.translations.product.loyalty_three_digit_separator);
              discountedPrice = splitFloatPrice.join('.');
            }
          } else {
            discountedPrice = Math.round(discountedPrice);
            if (site.translations.product.loyalty_three_digit_separator) {
              discountedPrice = discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, site.translations.product.loyalty_three_digit_separator);
            }
          }

          $(priceText[j]).find('.product-full__price').text(discountedPrice + ' ' + currency);
          if (el.met) {
            $('.header_price', context).text(discountedPrice + ' ' + currency);
          }
        }
        if (tierDescriptionList[i]) {
          var text = tierDescriptionList[i];
          $descriptionText.text(text);
        }
      });
    } else {
      $productLoyalty.hide();
    }
  },
  setEventsMPP: function(context, prodcatData, multipleProductsSection) {
    this.attached = true;
    var isCartConfirm = $(context).hasClass('cart-confirm-wrapper');
    var isExtraMPPSection = $(context).hasClass('js-loyalty-mpp-extra-section');
    var isProductSizeLoyalty = $(context).hasClass('js-size-price-loyalty');
    var $productLoyalty = isProductSizeLoyalty ? $(context) : $('.js-price-loyalty', context);
    var currency = $productLoyalty.attr('data-currency-symbol') || '';
    var loyaltyMppPrefix = '';
    var loyaltyMppSuffix = currency;
    var displayPriceAsFloat = Drupal.settings.globals_variables && !Drupal.settings.globals_variables.disable_float_price;
    var threeDigitSeparator = false;

    if (site.translations && site.translations.product) {
      loyaltyMppPrefix = page_data.is_anonymous_user ? site.translations.product.loyalty_program_mpp_price_prefix + ' ' : '';
      loyaltyMppSuffix = loyaltyMppSuffix ? loyaltyMppSuffix : site.translations.product.loyalty_program_mpp_price_suffix;
      currency = currency ? currency : site.translations.product.currency_symbol;
      if (site.translations.product.loyalty_three_digit_separator && multipleProductsSection !== 'account-landing-bestsellers') {
        threeDigitSeparator = site.translations.product.loyalty_three_digit_separator;
      }
    }

    var loyalty_Offers = page_data.offers_data.loyalty_Offers;
    var rangeSeparator = ' - ';
    var offerMet = false;

    $.each(loyalty_Offers, function(i, el) {
      if (el.met || (page_data.is_anonymous_user && loyalty_Offers.length === i + 1)) {
        offerMet = $.extend({}, el);
        offerMet.met = 1;
      }
    });

    if (isProductSizeLoyalty && $productLoyalty && $productLoyalty.length && offerMet) {
      var $options = $productLoyalty.find('option');
      $.each($options, function(k, el) {
        var $productPrice = $(this);
        var priceBase, discountedPrice;
        var isDiscountable = $productPrice.data('is-discountable') || 0;

        if (isDiscountable) {
          var size = $productPrice.data('loyalty-option-size') || $productPrice.data('sku-size');
          var price = $productPrice.data('loyalty-option-price') || $productPrice.data('sku-price');

          if (isExtraMPPSection && page_data.is_anonymous_user) {
            price = price + ' ' + currency;
            if (size) {
              $productPrice.html(size + rangeSeparator + price);
            } else {
              $productPrice.html(price);
            }
            return;
          }

          price = price.replace(/&#?[a-z0-9]+;/g, '');
          priceBase = price.split(' ' + currency).join('');

          discountedPrice = priceBase - priceBase * offerMet.discount / 100;
          if (!isCartConfirm) {
            discountedPrice = Math.round(discountedPrice);
          }
          price = loyaltyMppPrefix + discountedPrice + ' ' + currency;

          if (size) {
            $productPrice.html(size + rangeSeparator + price);
          } else {
            $productPrice.html(price);
          }
        }
      });
      Drupal.ELB.refreshSelectBoxes($productLoyalty);
    } else if ($productLoyalty && $productLoyalty.length && offerMet) {
      $productLoyalty = $productLoyalty.filter(function() {
        var mppSkuDiscountable = false;
        if (prodcatData) {
          var mppSkuProdId;
          if (multipleProductsSection === 'account-landing-bestsellers') {
            mppSkuProdId = $(this).closest('.my_account_product').data('productid');
          } else {
            mppSkuProdId = $(this).closest('.product').data('productid') || $(this).closest('.mpp-box').data('productid');
          }
          if (mppSkuProdId) {
            for (var i = 0; i < prodcatData.length; i++) {
              if (prodcatData[i].PRODUCT_ID === mppSkuProdId) {
                if (prodcatData[i].skus[0]) {
                  //Assumes all skus for a product are either discountable or not.
                  mppSkuDiscountable = prodcatData[i].skus[0].isDiscountable;
                }
                break;
              }
            }
          }
        } else {
          mppSkuDiscountable = true;
        }

        return mppSkuDiscountable;
      });
      var discountedSubtotal = 0;

      $.each($productLoyalty, function() {
        var $productPrice = $(this);
        if (isCartConfirm && $productPrice.hasClass('processed')) {
          return;
        }

        var len;
        if (isExtraMPPSection && page_data.is_anonymous_user) {
          if ($productPrice.length > 0) {
            len = $productPrice.length;
            for (var k = 0; k < len; k++) {
              var productPriceValue = $($productPrice[k]).text();
              var splittedPriceValue = productPriceValue.split(' ');
              if (!loyaltyMppPrefix.match(splittedPriceValue[0])) {
                $productPrice.text(loyaltyMppPrefix + productPriceValue);
              }
            }
          }
          return;
        }

        if ($productPrice.length > 0) {
          len = $productPrice.length;
          for (var j = 0; j < len; j++) {
            var textPrice, textPrice2, priceBase, priceBase2, discountedPrice, discountedPrice2, itemQuantity, splitFloatPrice;

            textPrice = $($productPrice[j]).text();
            textPrice = textPrice.replace(loyaltyMppPrefix, '');

            if (textPrice.indexOf(rangeSeparator) !== -1) {
              textPrice = textPrice.split(rangeSeparator);
            }
            if (typeof textPrice === 'string') {
              textPrice = textPrice.substring(0, textPrice.lastIndexOf(' ')).replace(/ /g, '');
              priceBase = parseFloat(textPrice);
            } else if (typeof textPrice === 'object') {
              textPrice2 = textPrice[1].substring(0, textPrice[1].lastIndexOf(' ')).replace(/ /g, '');
              priceBase2 = parseFloat(textPrice2);
              textPrice = textPrice[0].substring(0, textPrice[0].lastIndexOf(' ')).replace(/ /g, '');
              priceBase = parseFloat(textPrice);
            }
            var isDiscountable = $productPrice[j].data('is-discountable') || 0;
            if (isDiscountable) {
            discountedPrice = priceBase - priceBase * offerMet.discount / 100;
            if (isCartConfirm) {
              itemQuantity = parseInt($($productPrice[j]).parents('.qty-price').find('.qty').text());
              discountedSubtotal += discountedPrice * itemQuantity;
            } else {
              if (displayPriceAsFloat && textPrice.includes('.')) {
                discountedPrice = Number.parseFloat(discountedPrice).toFixed(2);
                if (threeDigitSeparator) {
                  splitFloatPrice = discountedPrice.toString().split('.');
                  splitFloatPrice[0] = splitFloatPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, threeDigitSeparator);
                  discountedPrice = splitFloatPrice.join('.');
                }
              } else {
                discountedPrice = Math.round(discountedPrice);
                if (threeDigitSeparator) {
                  discountedPrice = discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, threeDigitSeparator);
                }
              }
            }
            if (priceBase2 > 0) {
              if (page_data.is_anonymous_user) {
                discountedPrice2 = priceBase2;
              } else {
                discountedPrice2 = Math.round(priceBase2 - priceBase2 * offerMet.discount / 100);
              }
              if (displayPriceAsFloat && textPrice2.includes('.')) {
                discountedPrice2 = Number.parseFloat(discountedPrice2).toFixed(2);
                if (threeDigitSeparator) {
                  splitFloatPrice = discountedPrice2.toString().split('.');
                  splitFloatPrice[0] = splitFloatPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, threeDigitSeparator);
                  discountedPrice2 = splitFloatPrice.join('.');
                }
              } else {
                discountedPrice2 = Math.round(discountedPrice2);
                if (threeDigitSeparator) {
                  discountedPrice2 = discountedPrice2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, threeDigitSeparator);
                }
              }
              $productPrice.text(loyaltyMppPrefix + discountedPrice + ' ' + loyaltyMppSuffix + rangeSeparator + discountedPrice2 + ' ' + loyaltyMppSuffix);
            } else {
              $productPrice.text(loyaltyMppPrefix + discountedPrice + ' ' + loyaltyMppSuffix);
            }
            }
            $productPrice.addClass('processed');
          }
        }
      });
      if (isCartConfirm && discountedSubtotal > 0) {
        $('.subtotal_output span').text(parseFloat(discountedSubtotal).toFixed(2));
      }
    }
  },
  //changed to setEventsOther as the HP top products and Cart recommended products have the same behaviour
  setEventsOther: function(context, element_class, highest_discount, highest_disc_met) {
    this.attached = true;
    var fromMinPricePrefix = '';
    var currency = '';
    var productPrice = $(element_class, context);

    if (page_data.is_anonymous_user && site.translations && site.translations.product) {
      fromMinPricePrefix = site.translations.product.loyalty_program_mpp_price_prefix;
    }
    if (productPrice.length > 0) {
      highest_discount = highest_discount || 0;
      highest_disc_met = highest_disc_met || 0;
      var j, textPrice = '', priceBase = 0, discountedPrice = 0;

      if (page_data.is_anonymous_user && highest_discount > 0) {
        for (j = 0; j < productPrice.length; j++) {
          textPrice = $(productPrice[j]).text().trim();
          priceBase = textPrice.replace(/[^\d.-]/g, '');
          currency = currency || textPrice.split(' ').splice(-1);
          discountedPrice = Math.round(priceBase - priceBase * highest_discount / 100);
          $(productPrice[j]).text(fromMinPricePrefix + ' ' + discountedPrice + ' ' + currency);
          $(productPrice[j]).addClass('min-loyalty-price');
        }
      } else if (!page_data.is_anonymous_user && highest_disc_met > 0) {
        for (j = 0; j < productPrice.length; j++) {
          textPrice = $(productPrice[j]).text().trim();
          priceBase = textPrice.replace(/[^\d.-]/g, '');
          currency = currency || textPrice.split(' ').splice(-1);
          discountedPrice = Math.round(priceBase - priceBase * highest_disc_met / 100);
          $(productPrice[j]).text(discountedPrice + ' ' + currency);
          $(productPrice[j]).addClass('loyalty-price-met');
        }
      } else if (page_data.is_anonymous_user && highest_discount === 0) {
        for (j = 0; j < productPrice.length; j++) {
          textPrice = $(productPrice[j]).text().trim();
          priceBase = textPrice.replace(/[^\d.-]/g, '');
          currency = currency || textPrice.split(' ').splice(-1);
          $(productPrice[j]).text(fromMinPricePrefix + ' ' + priceBase + ' ' + currency);
          $(productPrice[j]).addClass('loyalty-full-price');
        }
      }
    }
  }
};
